import fetch from "./fetch";

const MCS01_URL = () => "/PC/WPC_MCS01";
const MCS01_01_URL = () => "/PC/WPC_MCS01_01";
const MCS01_02_URL = () => "/PC/WPC_MCS01_02";
const MCS01_03_URL = () => "/PC/WPC_MCS01_03";
const MCS01_04_URL = () => "/PC/WPC_MCS01_04";

export default {
  getMcs01: fetch("GET", MCS01_URL),
  getMcs0101: fetch("GET", MCS01_01_URL),
  getMcs0102: fetch("GET", MCS01_02_URL),
  getMcs0103: fetch("GET", MCS01_03_URL),
  getMcs0104: fetch("GET", MCS01_04_URL),
};
