<template>
    <!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					 {{t('10034')}} 
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub partnership">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="frms">
						<!-- 이름 -->
						<div class="frms__item">
							<h3 class="frms__title">{{t('10035')}}<span class="dot dot--red"></span> </h3>
							<div class="frms__wrap">
								<input type="text" class="ipt-frm" :placeholder="t('10043')" v-model="name">
								<div class="frms__btns">
									<button type="button" class="btn-authtel" v-show="name.length > 0" @click="()=> name = ''">
										<i class="icon icon-closesmall">{{t('10036')}}</i>
									</button>
								</div>
							</div>
						</div>
						<!-- 휴대폰 번호 -->
						<div class="frms__item">
							<h3 class="frms__title">{{t('10037')}}<span class="dot dot--red"></span> </h3>
							<div class="frms__wrap">
								<input type="tel" class="ipt-frm" :placeholder="t('10778')" :value="phoneNumber" @input="updatePhoneNumber($event)"  @keyup="checkNumber($event)" maxlength="13">
								<div class="frms__btns">
									<button type="button" class="btn-authtel" v-show="phoneNumber.length > 0" @click="()=> phoneNumber = ''">
										<i class="icon icon-closesmall">{{t('10036')}}</i>
									</button>
								</div>
							</div>
						</div>
						<!-- 이메일 -->
						<div class="frms__item">
							<h3 class="frms__title">{{t('10038')}}<span class="dot dot--red"></span> </h3>
							<div class="frms__wrap">
								<input type="email" class="ipt-frm" :placeholder="t('10826')"  @keyup="checkEmailFormat()" v-model="email">
								<div class="frms__btns">
									<button type="button" class="btn-authtel" v-show="email.length > 0" @click="()=> email = ''">
										<i class="icon icon-closesmall">{{t('10036')}}</i>
									</button>
								</div>
							</div>
						</div>
						<!-- 문의 내용 -->
						<div class="frms__item">
							<h3 class="frms__title">{{t('10039')}}<span class="dot dot--red"></span> </h3>
							<div class="review__text" style="margin-right:0">
								<textarea class="ta ta--transparent" style="height:94px" :placeholder="t('10780')" v-model="description" maxlength="1000"></textarea>
								<div class="review__number">
									<span>{{description.length}}</span> / 1000
								</div>
							</div>
						</div>
					</div>
					<ul class="agrees">
						<li style="border:none">
							<div class="checkbox">
								<label>
									<input type="checkbox" class="chk blind" v-model="isAgree">
									<span class="checkbox__bg"></span>
									<span class="agree__title">{{t('10040')}}</span>
									<div class="description" style="margin-top:10px;line-height:1.6">
                                         {{t('10041')}}
									</div>
								</label>
							</div>
						</li>
					</ul>
				</div>
				<div class="row">
					<!-- <button type="button" class="btn" :class="isVerified ? 'btn-confirm' : 'btn-disabled'" :disabled="!isVerified" @click="onClickSendPartnership">등록</button> -->
					<button type="button" class="btn" :class="isVerified ? 'btn-confirm' : 'btn-disabled'" :disabled="!isVerified" @click="onClickSendPartnership">등록</button>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { computed, ref } from "vue";
import api from "@/urls/mcs01";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const name = ref("");
		const phoneNumber = ref("");
		const email = ref("");
		const description = ref("");

		const openErrorAlert = ref(false)
		const isPhoneNumber = ref(false);
		const isEmail = ref(false);
		const isAgree = ref(false);
		const { t }= useI18n() //번역필수 모듈

		const isVerified = computed(() => {
			return isPhoneNumber.value && isEmail.value && isAgree.value && description.value.length > 10 && description.value.length <= 1000 && name.value.length > 0;
		});

		const onClickSendPartnership = async () => {
			if(isVerified.value) {
				const data = await api.getMcs0103({ 
					proc_cd: "01",
					name: name.value,
					hp_no: phoneNumber.value,
					email: email.value,
					request_desc: description.value,
				});

				if(data.data.Res_cd === "0000")	{
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "문의가 접수되었습니다."});
					openErrorAlert.value = true;
					//alert("문의가 접수되었습니다.");
					goBack();
				}
				else {
					alert(data.data.Res_msg);
				}
			}
		}

		const updatePhoneNumber = (e) => {
			phoneNumber.value = e.target.value;
		};

		const checkNumber = () => {
			const number = phoneNumber.value.replace(/[^0-9]/g, "");
			let phone = "";

			if(number.length < 4) { 
				phone = number; 
			}
			else if(number.length < 7) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3); 
			} 
			else if(number.length < 11) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3, 3); 
				phone += "-"; phone += number.substr(6); 
			} 
			else { 
				phone += number.substr(0, 3); 
				phone += "-"; 
				phone += number.substr(3, 4); 
				phone += "-"; 
				phone += number.substr(7); 
			}

			phoneNumber.value = phone;

			const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
			if(regPhone.test(phone)) {
				isPhoneNumber.value = true;
			} else {
				isPhoneNumber.value = false;
			}
		}

		const checkEmailFormat = () => {
			if(email.value.toLowerCase().match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)){
				isEmail.value = true;
			}else {
				isEmail.value = false;
			}
			//console.log(isEmail.value)
			//console.log(email.value)
		};

		const goBack = () => {
			router.back();
		}

		return {
			goBack,
			onClickSendPartnership,
			updatePhoneNumber,
			name,
			phoneNumber,
			email,
			description,
			checkNumber,
			checkEmailFormat,
			isAgree,
			isVerified,
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
		}
	},
}
</script>